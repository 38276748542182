import React from "react";

function Space({ height }) {
    return (
        <div style={{ height: height }}>
        </div>
    );
}

export default Space;
