import React from "react";
import "./css/InfoTitle.css";
import 'animate.css/animate.min.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';

function InfoTitle({ black, green, margin }) {
  return (
    <AnimationOnScroll animateIn="animate__bounceInDown" animateOnce>
    <div
      style={{ marginTop: margin }}
      data-aos="fade-down"
      data-aos-duration="1500"
    >
      <h2 className="infotitle text-center d-none d-md-block fade-down">
        <span className="infotitle-black">{black} </span>
        <span className="infotitle-green">{green}</span>
        <div className="mx-auto my-2"></div>
      </h2>
      <h4 className="infotitle-sm text-center d-md-none">
        <span className="infotitle-black">{black} </span>
        <span className="infotitle-green">{green}</span>
        <div className="mx-auto my-2"></div>
      </h4>
    </div>
    </AnimationOnScroll>
  );
}

export default InfoTitle;
